<script>
import TitleDescriptionSlot from '@/components/general/TitleDescriptionSlot'
import GenericSlider from '@/components/general/GenericSlider'
import TitleDescriptionCardAnchor from '@/components/general/TitleDescriptionCardAnchor'
import PendingMessage from '@/components/general/PendingMessage'
import PositionInvite from '@/components/general/PositionInvite'
export default {
  name: 'homepage-aggregators',
  components: {
    TitleDescriptionSlot,
    GenericSlider,
    TitleDescriptionCardAnchor,
    PendingMessage,
    PositionInvite,
    ConfirmationModal: () => import('@/components/general/ConfirmationModal')
  },
  data () {
    return {
      messagesCounter: 0,
      data: [],
      username: '',
      userApplicationInvites: [],
      userUnreadMessages: [],
      positionSuggestions: [],
      getApplications: [],
      getMatches: [],
      handleContentInProgress: [],
      handleUnreadMessages: [],
      courseSuggestions: [],
      pendingRemoteInterviews: [],
      isLoading: true,
      showModal: false
    }
  },
  computed: {
    aggregator () {
      return this.selectedWorkspace
    }
  },
  watch: {
    selectedWorkspace () {
      this.setup()
    }
  },
  created () {
    this.setup()
    this.$store.commit('updateLastWorkspaceSelected', 'aggregator')
  },
  methods: {
    setup () {
      Promise.all([
        this.$store.dispatch('attemptGetUserMessages', { aggregator: this.getCompanyDomainAndWorkspace() }),
        this.$store.dispatch('attemptGetPositionSuggestionsHome', { user: this.getUserInfo, aggregator: this.getCompanyDomainAndWorkspace() }),
        this.$store.dispatch('attemptGetCourseSuggestions', { aggregator: this.getCompanyDomainAndWorkspace() })
      ]).then(([dataUnreadMessages, dataPositionsSugestions, dataCourseSugestions]) => {
        this.userUnreadMessages = dataUnreadMessages

        this.positionSuggestions = dataPositionsSugestions.map(item => {
          if (!item.isFavorite) item.isFavorite = false
          return item
        })

        this.courseSuggestions = dataCourseSugestions

        if (this.userUnreadMessages.pending_applications > 0 || this.userUnreadMessages.pending_meetings > 0 || this.userUnreadMessages.unread_messages > 0 || this.userUnreadMessages.inprogress_applications) {
          this.messagesCounter = this.userUnreadMessages.unread_messages

          this.$store.dispatch('attemptGetUserMatches', { aggregator: this.getCompanyDomainAndWorkspace() }).then(data => {
            this.pendingRemoteInterviews = data.filter(match => { return match.position.meeting && match.position.meeting.accepted === false }).sort((a, b) => new Date(a.position.meeting.date) - new Date(b.position.meeting.date)).map(position => position.position)
            this.getMatches = data

            const allPositions = []
            for (const item of data) {
              allPositions.push(item.position)
            }

            this.getApplications = allPositions
            this.handleUnreadMessages = allPositions.filter(d => d.messagesCounter > 0).sort((a, b) => a.title.localeCompare(b.title))
          })
        }

        if (this.userUnreadMessages.pending_applications > 0) {
          this.$store.dispatch('attemptGetUserApplicationInvites', { aggregator: this.getCompanyDomainAndWorkspace() }).then(data => {
            this.userApplicationInvites = data
          })
        }
        this.isLoading = false
      })
    },
    handleMatches () {
      this.$store.dispatch('attemptGetUserMatches', { aggregator: this.getCompanyDomainAndWorkspace() })
    },
    handleMessages () {
      this.$store.dispatch('attemptGetUserMessages').then(dataUnreadMessages => {
        this.userUnreadMessages = dataUnreadMessages
      })
    },
    handleaUserApplicationInvites () {
      this.$store.dispatch('attemptGetUserApplicationInvites', { aggregator: this.getCompanyDomainAndWorkspace() }).then(data => {
        this.userApplicationInvites = data
      })
    },
    refreshPendingRemoteInterviews (type) {
      this.$store.dispatch('attemptGetUserMatches', { aggregator: this.getCompanyDomainAndWorkspace() }).then(data => {
        this.pendingRemoteInterviews = data.filter(match => match.position.meeting && match.position.meeting.accepted === false).sort((a, b) => new Date(a.position.meeting.date) - new Date(b.position.meeting.date)).map(position => position.position)
        if (type === 'confirm') this.showModal = true
      })
    }
  }
}
</script>
<template>
  <div class="homepage-aggregator--container">
    <div class="default-home-title" :class="{'mb-15': !selectedWorkspace.homepageBannerLink}">
      <div>
        <img :src="aggregator.avatar" :alt="$t(aggregator.name)" v-if="aggregator.avatar" />
        <h2>{{ $t('global:welcome') }}, {{ this.$store.getters.getUser.firstName }}! 👋</h2>
      </div>
      <p>{{ $t('home.greeting.aggregator.description', { name: selectedWorkspace.name }) }}</p>
    </div>

    <div class="homepage-aggregators--banners mb-12" v-if="!!selectedWorkspace.homepageBannerLink">
      <a :href="selectedWorkspace.homepageBannerLink" target="_blank">
        <img
          :src="`/assets/images/banners/${isMobile ? 'mobile' : 'web'}-banner-${selectedWorkspace.subdomain}.jpg`"
        >
      </a>
    </div>

    <title-description-slot v-if="userUnreadMessages.unread_messages" :title="$t('home.messages:not.read.yet')"
      :description="$t('home.messages:not.read.yet.description')">
      <pending-message v-for="item in handleUnreadMessages" :key="item.id" @refreshUnreadMessages="handleMessages()"
        :position="{ name: item.title, id: item.id }" :company="{ name: item.company.name, image: item.company.logo }"
        :unreadAmount="item.messagesCounter.toString()" />
    </title-description-slot>

    <title-description-slot v-if="userApplicationInvites.length" :key="userApplicationInvites.length"
      :title="$t('home.messages:invite.for.position')"
      :description="$t('home.messages:invite.for.position.description')">
      <position-invite v-for="(invite) in userApplicationInvites" :key="invite.id" :position="invite.position"
        :inviteId="invite.id" @refreshUserApplicationInvites="handleaUserApplicationInvites()"
        :company="invite.position.company" />
    </title-description-slot>

    <title-description-card-anchor v-if="userUnreadMessages.inprogress_applications" icon="mdi-list-status"
      :title="$t('home.card.not.finished.candidacy.title')"
      :description="$t('home.card.not.finished.candidacy.description')" :buttonTo="`/p/${$route.params.companyDomain}/applications/under-analysis`"
      :buttonText="$t('home.access.not.finished.candidacy')">

    </title-description-card-anchor>

    <GenericSlider v-if="pendingRemoteInterviews.length" :title="$t('home.messages:invite.for.remote.interview')" :items="pendingRemoteInterviews" type="pendingRemote" @emitGenericSlider="refreshPendingRemoteInterviews"/>

    <confirmation-modal
      :maxWidth="500"
      :show="showModal"
      :btnConfirmText="false"
      @cancel="showModal = false"
      :cancelText="$t('global:close')"
      :confirmText="$t('global:candidature.page')"
      :title="$t('invite:confirmation.modal.title')"
      @confirm="$router.push({ name: 'aggregator.candidature.under.analysis' })"
      :description="$t('invite:confirmation.modal.description')"
    />

    <div class="skeleton-container-aggregator" v-if="isLoading">
      <h5> {{ $t('home.positions.suggestions') }}</h5>
      <div class="skeleton-aggregator">
        <v-skeleton-loader class="skeleton-card-aggregator" type="card-avatar, article, actions" width="296px" height="380px"
          v-for="i in ['a', 'b', 'c']" :key="i" />
      </div>
    </div>

    <div class="skeleton-container-aggregator" v-if="isLoading">
      <h5> {{ $t('home.courses.suggestions') }}</h5>
      <div class="skeleton-aggregator">
        <v-skeleton-loader class="skeleton-card-aggregator" type="card-avatar, article, actions" width="296px" height="380px"
          v-for="i in ['a', 'b', 'c']" :key="i" />
      </div>
    </div>

    <div v-else>
      <GenericSlider v-if="positionSuggestions.length" title="home.positions.suggestions" :items="positionSuggestions" type="positions" />
      <GenericSlider v-if="courseSuggestions.length" title="home.courses.suggestions" :items="courseSuggestions" type="courses" />
    </div>
    <router-view />
  </div>
</template>
<style lang="scss">
.homepage-aggregator--container {
  margin-bottom: 40px;
  .homepage-aggregators--banners {
    img {
      border-radius: 4px;
      object-fit: contain;
      max-width: 100%;
      height: 100%;
    }
  }
  .skeleton-container-aggregator {
    margin-bottom: 62px;
    h5 {
      font-size: 16px;
      line-height: 52px;
    }

    .skeleton-aggregator {
      display: flex;

      .skeleton-card-aggregator {
        margin-right: 20px;
      }
    }
  }
}
</style>
